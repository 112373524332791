<template>
  <v-container fluid>
    <h3 class="my-1">
      SERVICIOS A REALIZAR:<v-btn
        icon
        color="primary"
        @click="dialog = true"
        v-if="tipo !== 'ver'"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </h3>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3" v-if="this.services">
        <h4 class="mb-2">ORIGEN</h4>
        <v-checkbox
          dense
          v-for="service in services.filter(
            (v) => v.id_begend == 1 || v.id_begend == 6
          )"
          :key="service.id"
          v-model="service.status"
          :clearable="tipo != 'ver'"
          :readonly="tipo == 'ver'"
        >
          <template v-slot:label>
            {{ service.namegroupservice }}
          </template>
        </v-checkbox>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3" v-if="this.services">
        <h4 class="mb-2">
          FLETE:
          <v-icon v-if="datosPrincipales.idshipment == 1">mdi-airplane</v-icon>
          <v-icon v-else>mdi-ferry</v-icon>
        </h4>
        <v-checkbox
          dense
          v-for="service in services.filter(
            (v) => v.codegroupservices == 4 || v.id_begend == 8
          )"
          :key="service.id"
          v-model="service.status"
          :clearable="tipo != 'ver'"
          :readonly="tipo == 'ver'"
        >
          <template v-slot:label>
            {{ service.namegroupservice }}
          </template>
        </v-checkbox>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3" v-if="this.services">
        <h4 class="mb-2">DESTINO</h4>
        <v-checkbox
          dense
          v-for="service in services.filter(
            (v) => v.id_begend == 2 || v.id_begend == 7
          )"
          :key="service.id"
          v-model="service.status"
          :clearable="tipo != 'ver'"
          :readonly="tipo == 'ver'"
        >
          <template v-slot:label>
            {{ service.namegroupservice }}
          </template>
        </v-checkbox>

        <v-autocomplete
          v-if="
            services.filter((v) => v.codegroupservices == 14).length > 0
              ? services.filter((v) => v.codegroupservices == 14)[0].status
              : false
          "
          auto-select-first
          :items="provincias"
          label="Provincias"
          dense
          @change="getTown"
          v-model="datosPrincipales.idprovincia"
          :clearable="tipo != 'ver'"
          :readonly="tipo == 'ver'"
        ></v-autocomplete>

        <v-autocomplete
          v-if="
            services.filter((v) => v.codegroupservices == 14).length > 0
              ? services.filter((v) => v.codegroupservices == 14)[0].status
              : false
          "
          auto-select-first
          :items="distritos"
          label="Distritos"
          v-model="datosPrincipales.iddistrito"
          dense
          :clearable="tipo != 'ver'"
          :readonly="tipo == 'ver'"
        ></v-autocomplete>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-3" v-if="this.services">
        <h4 class="mb-2">OPCIONAL</h4>
        <v-checkbox
          dense
          v-for="service in services.filter(
            (v) => v.id_begend == 4 || v.id_begend == 9
          )"
          :key="service.id"
          v-model="service.status"
          @click="setImpuestoFlag"
          :clearable="tipo != 'ver'"
          :readonly="tipo == 'ver'"
        >
          <template v-slot:label>
            {{ service.namegroupservice }}
          </template>
        </v-checkbox>

        <v-text-field
          v-show="
            (this.services.filter((v) => v.codegroupservices == 15).length > 0
              ? this.services.filter((v) => v.codegroupservices == 15)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 2).length > 0
              ? this.services.filter((v) => v.codegroupservices == 2)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 6).length > 0
              ? this.services.filter((v) => v.codegroupservices == 6)[0].status
              : false)
          "
          @keyup="setImpuestoFlag"
          prefix="$"
          v-model="datosPrincipales.amount"
          label="Valor de la mercancia"
          type="number"
          step="0.10"
          min="0"
          placeholder="Valor de la mercancia..."
          autocomplete="off"
          :clearable="tipo != 'ver'"
          :readonly="tipo == 'ver'"
          dense
        >
        </v-text-field>
        <v-text-field
          v-if="
            (this.services.filter((v) => v.codegroupservices == 15).length > 0
              ? this.services.filter((v) => v.codegroupservices == 15)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 2).length > 0
              ? this.services.filter((v) => v.codegroupservices == 2)[0].status
              : false) ||
            (this.services.filter((v) => v.codegroupservices == 6).length > 0
              ? this.services.filter((v) => v.codegroupservices == 6)[0].status
              : false)
          "
          v-model="datosPrincipales.descripcioncarga"
          label="Descripción de carga"
          placeholder="Descripción de carga"
          autocomplete="off"
          :clearable="tipo != 'ver'"
          :readonly="tipo == 'ver'"
          dense
        >
        </v-text-field>
      </div>
    </div>
    <v-flex text-right v-if="tipo == 'nuevo'">
      <v-btn
        class="mx-1"
        color="#5AB55E"
        small
        scrollable
        @click="dialogCotizar = true"
      >
        <b>Continuar</b> <v-icon class="">mdi-chevron-right</v-icon>
      </v-btn>
    </v-flex>
    <!-- MODAL AGREGAR -->
    <v-dialog v-model="dialog" max-width="450px">
      <v-card>
        <v-card-title primary-title>
          <h3>Nuevo Servicio :</h3>
        </v-card-title>
        <v-card-text>
          <v-form ref="nuevoServicio">
            <v-autocomplete
              dense
              autocomplete="none"
              :items="begEndList.filter((v) => v.id != 5)"
              label="Tipo de Servicio"
              :value="begEndList.id"
              v-model="fromDataService.id_begend"
              :rules="[(v) => !!v || 'Dato requerido']"
            ></v-autocomplete>

            <v-text-field
              dense
              label="Descripción"
              :rules="[(v) => !!v || 'Dato requerido']"
              v-model="fromDataService.namegroupservice"
            >
            </v-text-field>

            <v-flex text-right>
              <v-btn small outlined color="success" @click="guardarServicio">
                Aceptar
              </v-btn>
            </v-flex>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCotizar" max-width="300px">
      <v-card>
        <v-card-title primary-title> Pasar a Cotizar </v-card-title>
        <v-card-text>
          <h2>¿Desea pasar a cotizar?</h2>
          <v-flex text-right>
            <v-btn
              dark
              small
              @click="aceptar()"
              class="mx-2 mt-4"
              color="success"
              >Si</v-btn
            >
            <v-btn
              dark
              small
              class="mx-2 mt-4"
              @click="guardar()"
              color="warning"
              >No</v-btn
            >
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Swal from "sweetalert2";
export default {
  props: ["tipo"],
  name: "cardServicioComponent",
  components: {
    // servicioSet: () => import("../comun/guardarServicioComponent.vue"),
  },

  methods: {
    ...mapMutations([
      "updateDatosCostos",
      "setServices",
      "setCostos",
      "setFactor",
      "setDistritos",
      "setImpuestoFlag",
      "setBotonRegistrarFlag",
      "setCudFlag",
    ]),
    guardar() {
      let imp =
        this.services.filter((v) => v.codegroupservices == 15).length > 0
          ? this.services.filter((v) => v.codegroupservices == 15)[0].status
          : false;
      let aduana =
        (this.services.filter((v) => v.codegroupservices == 2).length > 0
          ? this.services.filter((v) => v.codegroupservices == 2)[0].status
          : false) ||
        (this.services.filter((v) => v.codegroupservices == 6).length > 0
          ? this.services.filter((v) => v.codegroupservices == 6)[0].status
          : false);
      // ---------------------------------------
      if (!this.datosPrincipales.amount && (imp || aduana)) {
        Swal.fire({
          icon: "error",
          title: "Ocurrió un error",
          text: "Para servicios Aduanas o Impuesto se requiere un monto",
        });
      } else {
        this.setCudFlag(false); // este
      }
      this.dialogCotizar = false;
    },
    aceptar() {
      let imp =
        this.services.filter((v) => v.codegroupservices == 15).length > 0
          ? this.services.filter((v) => v.codegroupservices == 15)[0].status
          : false;
      let aduana =
        (this.services.filter((v) => v.codegroupservices == 2).length > 0
          ? this.services.filter((v) => v.codegroupservices == 2)[0].status
          : false) ||
        (this.services.filter((v) => v.codegroupservices == 6).length > 0
          ? this.services.filter((v) => v.codegroupservices == 6)[0].status
          : false);
      // ---------------------------------------
      if (!this.datosPrincipales.amount && (imp || aduana)) {
        Swal.fire({
          icon: "error",
          title: "Ocurrió un error",
          text: "Para servicios Aduanas o Impuesto se requiere un monto",
        });
      } else {
        this.updateDatosCostos();
        this.setBotonRegistrarFlag(true);
      }
      this.dialogCotizar = false;
      // id_services // esopcionflag
      // this.updateDatosCostos;
    },
    guardarServicio() {
      if (this.$refs.nuevoServicio.validate()) {
        let data = {
          namegroupservice: this.fromDataService.namegroupservice,
          id_begend: this.fromDataService.id_begend,
          id_role: this.fromDataService.id_role,
          price: this.fromDataService.price,
          esopcionflag: 0,
          status: 1,
          statedelete: 0,
        };
        this.services.push(data);
        // this.costos.push(data);
        this.setServices(this.services);
        this.dialog = false;
        // this.datosPrincipales.services.push(this.fromDataService);
        this.$refs.nuevoServicio.reset();
      }
    },
    async getTown() {
      let valores = await this.obtenerDataApi(
        "post",
        process.env.VUE_APP_URL_MAIN + "getTown",
        { idCity: 128 }
      );
      let provincias = [];
      valores.forEach((element) => {
        provincias.push({
          value: element.id,
          text: element.name,
        });
      });
      this.setDistritos(provincias);
    },
  },
  data() {
    return {
      hoy: new Date(),
      menu: false,
      guardarDatosCostos: false,
      dialogCotizar: false,
      dialog: false,
      fromDataService: { esopcionflag: 0, status: 1, statedelete: 0, price: 0 },
    };
  },
  computed: {
    ...mapState([
      "services",
      "begEndList",
      "datosPrincipales",
      "provincias",
      "distritos",
      "cargarServicios",
    ]),
  },
  // watch: {},
};
</script>

<style></style>
